<template>
  <div id="petproduct-view" class="mt-2">
    <div class="container-fluid">
      <center>
        <div class="product-view-heading">
          <div class="empty"></div>
          <div v-if="products.length > 0" class="product-view-title">
            <h1 class="display-1 mb-0" style="font-weight: 400">
              <img
                class="giftbox-img mr-2"
                src="@/assets/site/images/gift-box-left.svg"
                alt=""
              />
              GIFTS
              <img
                class="giftbox-img ml-2"
                src="@/assets/site/images/gift-box-right.svg"
                alt=""
              />
            </h1>
          </div>
          <div @click="openWizardresult" class="product-view-redo">
            <span class="redo-text">REDO</span>
            <img
              v-if="pet_image"
              :src="pet_image"
              class="avatar-img-gift"
              alt=""
            />
            <img
              v-else
              src="@/assets/site/images/defaultPetImage.webp"
              class="avatar-img-gift"
              alt=""
            />
          </div>
        </div>
      </center>
      <div v-if="loading" class="col-md-12 row">
        <span class="col-md-2"></span>
        <img
          src="@/assets/site/images/loader.gif"
          class="img-fluid loader-width col-md-8"
          alt
        />
        <span class="col-md-2"></span>
      </div>
      <div
        v-if="productShow && !loading"
        class="product-slider product-slider-1 mt-4"
      >
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <div v-if="products.length > 0" class="row">
            <div
              class="col-md-3"
              v-for="product in products"
              :id="product.productId"
              :key="product.mid"
              :product-attribute="product.keywords"
            >
              <div
                @click="ViewProduct(product)"
                class="owl-item active wizard-box"
              >
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right"></div>
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          :title="
                            contactName
                              ? `Add to Favourite for ${contactName}`
                              : 'Add to Favourite'
                          "
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="savetoWishlist(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          :title="
                            contactName
                              ? `Add to Wish List for ${contactName}`
                              : 'Add to Wish List'
                          "
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>
                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                            ><span class="price"
                              >{{ product.price["@currency"] }}
                              {{ product.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view"> &nbsp;<span>Review</span></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center v-if="suggested_products.length > 0">
            <div class="findgft mb-4">
              <h1 class="display-1 mb-0" style="font-weight: 400">
                <span>
                  <img
                    class="giftbox-img mr-2"
                    src="@/assets/site/images/gift-box-left.svg"
                    alt=""
                /></span>
                Suggested gifts
                <span>
                  <img
                    class="giftbox-img ml-2"
                    src="@/assets/site/images/gift-box-right.svg"
                    alt=""
                /></span>
              </h1>
            </div>
          </center>
          <div class="row">
            <div
              class="col-md-3"
              @click="ViewProduct(product)"
              v-for="product in suggested_products"
              :key="product.productId"
            >
              <div class="owl-item active wizard-box">
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          :title="
                            contactName
                              ? `Add to Favourite for ${contactName}`
                              : 'Add to Favourite'
                          "
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="savetoWishlist(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          :title="
                            contactName
                              ? `Add to Wish List for ${contactName}`
                              : 'Add to Wish List'
                          "
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>
                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>
                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                            ><span class="price"
                              >{{ product.price["@currency"] }}
                              {{ product.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <a
                          :href="product.linkurl"
                          target="_blank"
                          class="action primary tocart"
                          id="product-addtocart-button"
                        >
                          <span>Buy Now</span>
                        </a>

                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="suggested_products.length"
            class="row my-4 d-flex justify-content-center"
          >
            <app-pagination
              :currentPage="page"
              :lastPage="totalPages"
              :hasNextPage="page !== totalPages"
              :changePage="changePage"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
      <single-product
        v-if="!productShow"
        v-bind:gender="gender"
      ></single-product>
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>

<script>
import SingleProduct from "@/views/site/Product";
import OccasionModal from "@/views/site/OccasionModal";
import AppPagination from "@/views/site/blocks/Pagination";
import PetwizardView from "@/views/site/petwizard/PetwizardView";

export default {
  name: "PetproductView",
  metaInfo: {
    title: "Pet Products",
    meta: [
      {
        name: "description",
        content:
          "Explore a delightful range of gifts for your pets! Browse through a curated selection of products designed to pamper and please your furry friends. Enjoy features like quick views, wish lists, and user-friendly navigation for an enhanced shopping experience.",
      },
      {
        name: "keywords",
        content:
          "pet gifts, pet products, gift ideas for pets, browse pet gifts, pet product gallery, quick view pet products, add to wishlist, favorite pet gifts, suggested pet gifts, pet product reviews, online pet shopping, pet accessories, pet care gifts, shopping for pets, pet lovers gifts",
      },
    ],
  },
  data() {
    return {
      products: [],
      suggested_products: [],
      loader: true,
      loading: false,
      selected_friend: null,
      productShow: true,
      wizard_data: [],
      wizardData: null,
      action: "",
      pet_id: null,
      page: 1,
      totalPages: null,
      select_pet_name: null,
      user_contact_name: null,
      contactName: null,
      occasion_id: null,
      wishlist_product: null,
      pet_image: null,
    };
  },
  components: {
    SingleProduct,
    OccasionModal,
    AppPagination,
    PetwizardView,
  },
  created() {
    if (this.$store.state.petWizardData) {
      this.wizardData = this.$store.state.petWizardData;
    }
    if (this.$store?.state?.page) {
      this.page = this.$store.state.page;
    }
    this.totalPages = this.$store.state.totalPages;
    this.products = this.$store.state.pet_wizardProducts_items;
    this.wizard_data = this.$store.state.wizard_data;
    this.contactName = JSON.parse(this.$store.state.wizard_data).name;
    this.pet_id = this.$store.state.pet_id;
    this.user_contact_name = this.$store.state.user_contact_name;
    this.select_pet_name = this.$store.state.pet_name;
    this.suggested_products = this.$store.state.pet_wizardProducts_suggested_items;
    this.occasion_id = this.$store.state.occasion_id;
    this.pet_image = this.$store.state.pet_image;
  },
  methods: {
    changePage(page) {
      if (!this.loading) {
        this.moreProducts(page, this.wizardData);
        window.scrollTo({
          top: 100,
          behavior: "smooth",
        });
      }
    },
    moreProducts(page, wizardData) {
      this.loading = true;

      const token = localStorage.getItem("userData");
      const userdata_array = JSON.parse(token);
      const api_token = userdata_array.token;
      const fetch_url =
        process.env.VUE_APP_URL + "customer/products/getWizardPetProducts";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          ...wizardData,
          page,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.products = data.response.items;
          this.suggested_products = data.response.suggested_items;
          this.$store.state.pet_wizardProducts_items = data.response.items;
          this.$store.state.pet_wizardProducts_suggested_items =
            data.response.suggested_items;
          this.totalPages = data.response.pagination.max_page;
          this.page = page;
          this.loading = false;
          this.$store.state.page = page;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
          this.loading = false;
        });
    },
    openWizardresult() {
      this.$store.state.comeFromRedo = true;
      this.$bvModal.show("petwizard-modal");
    },
    savegift(product) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);

      if (!userdata_array) {
        $("#myModal").modal("show");
        return;
      }

      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/savegift";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product: product,
          user_id: user_id,
          product_id: product.productId,
          user_contact_id: this.selected_friend,
          clickstream_data: this.wizard_data.wizard_details,
          user_action: "SavedForGiftee",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    savetoWishlist(product) {
      this.$bvModal.hide("occasion-modal");
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);

      if (!userdata_array) {
        $("#myModal").modal("show");
        return;
      }
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          type: "pet_wishlist",
          product_id: product.productId,
          product: product,
          user_id: user_id,
          clickstream_data: this.wizard_data.wizard_details,
          user_action: "AddToWishlist",
          wishlist_type: "Pet",
          pet_id: this.pet_id,
          occasion_id: this.occasion_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;

            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });

      var fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/saveAffilativeNetwork/" +
        product.productId;
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {});

      this.wishlist_product = null;
    },
    savetoFavourite(product) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);

      if (!userdata_array) {
        $("#myModal").modal("show");
        return;
      }

      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: product.productId,
          product: product,
          user_id: user_id,
          clickstream_data: this.wizard_data?.wizard_details,
          user_action: "AddToFav",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    compareProduct(product) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);

      if (!userdata_array) {
        $("#myModal").modal("show");
        return;
      }

      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/compare";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: product.productId,
          product: product,
          user_id: user_id,
          clickstream_data: this.wizard_data.wizard_details,
          user_action: "compare",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    ViewProduct(product) {
      var selected_friend = this.selected_friend;
      var user_contact_name = this.user_contact_name;
      var pet_name = this.select_pet_name;
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: {
          singleproduct: product,
          allProducts_items: this.products,
          allProducts_suggested_items: this.suggested_products,
          selected_friend: selected_friend,
          clicked_wizarddata: this.wizard_data.wizard_details,
          user_contact_name: user_contact_name,
          pet_name: pet_name,
          type: "pet",
        },
      });
    },
  },
};
</script>

<style scoped>
.product-view-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product-view-heading .empty {
  margin-right: auto;
  margin-left: 24px;
  width: 88px;
}
@media screen and (max-width: 640px) {
  .product-view-heading .empty {
    width: 40px;
  }
}

.product-view-heading .product-view-title {
  margin: auto;
  color: #c8730e;
}

.product-view-heading .product-view-redo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  margin-right: 24px;
  cursor: pointer;
}

.redo-text {
  color: #38afdf;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 640px) {
  .product-view-heading .product-view-redo {
    margin-right: 0;
  }
}
</style>
